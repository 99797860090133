.time-th{
    min-width: 32px;
    width: 14%;
}

.time-td{
    min-width: 32px;
    width: 14%;
}

.time-table{
    width: 100%;
    height: calc(100vh - 130px);
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
}

.time-button{
    position: relative;
    left: 0;
    width: 100px;
    height: 100px;
}
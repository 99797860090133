.plan-header{
    width: 90%;
    height: 48px;
    line-height: 48px;
    font-size: 20px;
    box-sizing: border-box;
    margin: 40px auto 30px auto;
    display: flex;
    justify-content: center;
}

.plan-input{
    display: inline-block;
    width: 90%;
    max-width: calc(100% - 48px);
    min-height: 48px;
    height: 48px;
    padding: 4px 10px;
    border: 1px solid #ddd;
    border-radius: 48px;
    box-sizing: border-box;
}

.plan-button{
    display: inline-block;
    font-size: 22px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    line-height: 52px;
    border: 1px solid #ddd;
    background-color: #fff;
    margin: 0px 8px;
}

.plan-table{
    width: calc(100% - 20px);
    max-width: 98%;
    height: calc(100vh - 193px);
    overflow: scroll;
    margin: 0 auto;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
}

.plan-table::-webkit-scrollbar {
    display: none;
}

.plan-card{
    display: inline-block;
    min-width: 280px;
    max-width: 400px;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 10px 10px;
    background-image: url('https://www.w3schools.com/w3images/forestbridge.jpg');
    overflow: hidden;
}

@media (min-width: 1111px){
    .plan-card{
        width: calc(100%);
        max-width: 340px;
    }
}

@media (max-width: 1110px){
    .plan-card{
        width: calc((100% - 60px) / 2);
    }
}


@media (max-width: 680px){
    .plan-card{
        width: calc(100% - 20px);
    }
}



.plan-title{
    width: calc(100% - 20px);
    margin-top: 127px;
    height: 72px;
    line-height: 36px;
    font-size: 20px;
    text-align: left;
    padding: 0 10px;
    border-top: 1px solid #ddd;
    background-color: white;
    bottom: 0;
}

.plan-text{
    width: 100%;
    height: 36px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.plan-button-div{
    width: 100%;
    height: 36px;
    padding: 0;
}

.plan-like{
    width: 80px;
    max-width: 120px;
    display: flex;
    height: 34px;
    line-height: 10px;
    font-size: 22px;
    margin: 0;
    margin-left: calc(100% - 80px);
    border: none;
    background-color: white;
}

.plan-like-icon{
    position: relative;
    width: 26px;
    height: 26px;
    font-size: 22px;
    margin: 4px 0px;
    padding: 3px 0px;
    box-sizing: border-box;
    background-color: white;
}

.plan-like-text{
    position: relative;
    width: 50px;
    height: 34px;
    line-height: 34px;
    font-size: 20px;
    margin: 0;
    background-color: white;
    overflow: hidden;
}

.plan-make{
    width: 90%;
    margin: auto;
}

.plan-make-header{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    padding: 4px 4px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
}

.plan-make-icon{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    margin: 0;
    background-color: white;
}

.plan-make-task{
    width: 100%;
    height: 136px;
    line-height: 36px;
    font-size: 20px;
    margin: 16px 0;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 10px;
    justify-content: center;
    overflow: hidden;
}

.plan-make-button{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    box-sizing: border-box;
    display: flex;
    margin: 10px 0;
    justify-content: center;
}

.circle{
    width: 44px;
    height: 44px;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 30px;
    border: 1px solid #ddd;
}

.small{
    width: 24px;
    height: 24px;

}

.plan-make-task-info{
    width: 100%;
    height: 102px;
    line-height: 36px;
    font-size: 20px;
    text-align: left;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: white;
    bottom: 0;
}

.plan-make-list-header{
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 20px;
    padding: 4px 4px 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: right;
}

.plan-make-head-title{
    width: 100%;
    max-width: 600px;
    height: 40px;
    line-height: 40px;
    font-size: 28px;
    padding: 0 4px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    text-align: center;
    margin: 10px 0;
}

.plan-make-input-title{
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 20px;
    padding: 0 4px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    margin: 0;
}


.plan-make-input-option{
    width: calc(50% - 24px);
    max-width: 200px;
    height: 34px;
    line-height: 34px;
    font-size: 20px;
    padding: 0 4px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    margin: 0;
}

.plan-make-submit{
    width: 80%;
    max-width: 240px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    background-color: white;
    justify-content: center;
}
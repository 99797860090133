.card{
    display: block;
    min-width: 280px;
    max-width: 800px;
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px #ddd;
    margin: 20px auto;
    box-sizing: border-box;
    background-image: url('https://www.w3schools.com/w3images/forestbridge.jpg');
    overflow: hidden;
}

.card-selected{
    display: block;
    min-width: 280px;
    max-width: 800px;
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0px auto;
    box-sizing: border-box;
    background-image: url('https://www.w3schools.com/w3images/forestbridge.jpg');
    overflow: hidden;
}

.card-title{
    width: calc(100% - 20px);
    margin-top: 127px;
    height: 72px;
    line-height: 36px;
    font-size: 20px;
    text-align: left;
    padding: 0 10px;
    border-top: 1px solid #ddd;
    background-color: white;
    bottom: 0;
}

.card-text{
    width: 100%;
    height: 34px;
    line-height: 34px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}

.card-text-icon{
    display: inline-block;
    width: 28px;
    height: 34px;
    padding: 7px 4px;
    text-align: center;
    box-sizing: border-box;
}

.card-text-span{
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0;
    padding-right: 4px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-option{
    display: block;
    min-width: 280px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    border: 1px solid #ddd;
    box-shadow: 0px 10px 10px 0px #ddd;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    padding: 4px 8px;
    margin: 0 auto;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.card-button-div{
    display: flex;
    width: 100%;
    height: 48px;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    border-top: 1px solid #ddd;
    box-sizing: border-box;
}

.card-option-button{
    display: inline-block;
    width: calc(100% / 3);
    min-width: 48px;
    height: 48px;
    line-height: 48px;
    padding: 10px 0;
    font-size: 20px;
    text-align: center;
    border: none;
    background-color: white;
    box-sizing: border-box;
}

@keyframes card-option-appear{
    0%{
        height: 0px;
        min-height: 0px;
    }
    50%{
        height: 220px;
        min-height: 220px;
    }
    100%{
    }
}
.week-th{
    min-width: 32px;
    width: 14%;
}

.week-tr{
    display: flex;
    width: 100%;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
}

.week-th{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    padding: 0;
}

.week-tbody{
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    z-index: 10;
}

.week-scroll{
    display: flex;
    height: 100%;
}

.week-td{
    min-width: 200px;
    width: calc(100%/7);
    height: 100%;
    text-align: center;
    padding: 0;
}

.week-p{
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
    font-size: 16px;
    margin-top: 4px;
}

.week-header{
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ddd;
    line-height: 50px;
    font-size: 20px;
    justify-content: center;
}

.week-table{
    width: 100%;
    height: calc(100vh - 104px);
    border-collapse: collapse;
    border-spacing: 0;
    padding: 4px 0px;
    overflow: scroll;
}

.week-drag{
    max-width: 80%;
    margin: auto;
}

.week-drop{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.week-drop-background{
    background-color: rgba(200, 250, 200, 0.5);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.week-drop-background::-webkit-scrollbar {
    display: none;
}

.week-drop::-webkit-scrollbar {
    display: none;
}

.week-table::-webkit-scrollbar {
    display: none;
}

.week-title{
    display: inline-block;
    width: 240px;
}

.week-left{
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 11px 11px;
}

.week-right{
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 11px 4px;
}
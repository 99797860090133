.footer{
    display: block;
    position: absolute;
    z-index: 5;
    bottom: 0;
    width: 100%;
}

.footer-input{
    display: inline-block;
    width: calc(100% - 140px);
    line-height: 48px;
    height: 48px;
    font-size: 16px;
    background-color: #e2e2ee;
    border: none;
    border-radius: 48px;
    margin: 15px 0;
    padding: 0 16px;
}

.submit-button{
    display: inline-block;
    line-height: 44px;
    font-size: 18px;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    border: none;
    background-color: #ddd;
    margin: 15px 8px;
    padding: 0;
}

.button-icon{
    width: 20px;
    height: 20px;
    margin: 13px 3px 12px 0px;
}

.form-div{
    background-color: white;
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: center;
}

.footer-div{
    min-width: 10px;
    height: 50px;
    border-bottom: 1px solid #ddd;
    text-align: right;
}

.footer-day{
    position: absolute;
    line-height: 48px;
    font-size: 18px;
    width: 140px;
    height: 50px;
    margin: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-bottom: none;
    left: 0;
}

.table-button{
    line-height: 54px;
    font-size: 22px;
    width: calc(100% / 5);
    height: 50px;
    margin: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-bottom: none;
}

.suggest-div{
    width: 100%;
    height: 130px;
    background-color: white;
    border-bottom: 1px solid #ddd;
    text-align: right;
    animation: fadeInUp 0.4s ease-in-out;
    padding-top: 10px;
}

.suggest-card{
    width: calc(100% - 50px);
    padding: 0 10px;
    line-height: 36px;
    height: 36px;
    background-color: #e2e2ee;
    border: none;
    border-radius: 36px;
    margin: auto;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: auto;
    margin-bottom: 6px;
}

@keyframes fadeInUp{
    0%{transform:translate(0px, 140px); opacity: 0;}
    100%{transform:translate(0px, 0); opacity: 1;}
  }
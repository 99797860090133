

.App {
  text-align: center;
  width: 100%;
  max-width: 1200px;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  overflow: hidden;
  margin: auto;
}

.main{
  overflow: hidden;
}

.planner{
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  overflow: hidden;
}

:root {
  --vh: 100%;
}
.task-div{
    min-width: 100px;
    width: 80%;
    max-width: 600px;
    height: 500px;
    max-height: 80vh;
    background-color: white;
    top: max(50px, calc(50% - 250px));;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    border: 1px solid black;
    border-radius: 10px;
}

.task-title{
    width: calc(100% - 120px);
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    text-align: center;
    border: none;
    margin: 0;
}

.task-bell{
    position: absolute;
    right: 36px;
    top: 0;
    margin: 13px 0px;
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 24px;
}

.task-close{
    position: absolute;
    right: 0;
    top: 0;
    margin: 13px 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 24px;
}

.task-list-div{
    width: 100%;
    height: 280px;
}

.task-list{
    width: 96%;
    max-width: 550px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    margin: 0px auto;
    padding: 0 4px;
    font-size: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    display: flex;
}

.task-time{
    width: 96%;
    max-width: 550px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 0px auto;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
    display: flex;
}

.task-list-title{
    display: inline-block;
    width: calc(100% - 60px);
}

.task-icon{
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px 0;
    text-align: center;
}

.task-list-option-div{
    display: inline-block;
    width: 60px;
    height: 40px;
    text-align: center;
}

.task-list-button{
    display: inline-block;
    width: 60px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    text-align: center;
}

.task-detail-day{
    width: calc(100% / 7);
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.task-detail{
    width: 96%;
    max-width: 550px;
    height: 40px;
    text-align: left;
    margin: 0px auto;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #ddd;
    overflow-x: scroll;
    display: flex;
}

.task-detail-input{
    width: 40px;
    height: 38px;
    text-align: center;
    border: none;
}

.task-input{
    display: block;
    width: 96%;
    max-width: 550px;
    min-height: 48px;
    height: 84px;
    max-height: 84px;
    margin: 4px auto;
    padding: 4px 10px;
    border: none;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    resize: none;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Raleway', sans-serif;
}

.task-input::-webkit-scrollbar {
    display: none;
}

.task-trash{
    position: absolute;
    right: 80px;
    bottom: 0;
    margin: 4px 4px;
    width: 100px;
    height: 40px;
    text-align: right;
    font-size: 24px;

}

.task-option-div{
    width: calc(100% - 10px);
    height: 50px;
    margin: auto;
    text-align: right;
}

.task-option{
    display: inline-block;
    width: 40px;
    height: 50px;
    line-height: 30px;
    font-size: 28px;
    text-align: center;
    margin: 0 4px;
}

.task-level{
    display: inline-block;
    position: absolute;
    right: 40px;
    bottom: 0;
    width: 40px;
    height: 40px;
    line-height: 30px;
    font-size: 28px;
    text-align: center;
    margin: 4px 4px;
}

.task-checkbox{
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    line-height: 30px;
    font-size: 24px;
    text-align: center;
    margin: 2px 4px;
}

.task-component{
    width: 100%;
    height: 100%;
}

.task-number-scroll{
    display: inline-block;
    height: 40px;
}

.task-time-div{
    display: inline-block;
    width: calc(100% - 40px);
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: none;

}

.task-daybox{
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: none;
}

.task-number-scroll::-webkit-scrollbar {
    display: none;
}

.task-number-scroll-element{
    width: 100%;
    height: 38px;
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 0;
    border: none;
}


.task-detail-element{
    flex: 0 0 auto;
    display: inline-block;
    min-width: 60px;
    max-width: 100px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    margin: 7px 4px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.task-detail-element-color{
    flex: 0 0 auto;
    display: inline-block;
    min-width: 60px;
    max-width: 100px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    margin: 7px 4px;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #e2e2ee;
}

.task-color-button{
    flex: 0 0 auto;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    margin: 4px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden;
}

.task-color-button-selected{
    flex: 0 0 auto;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    margin: 3px 4px;
    padding: 0;
    border: 2px solid #222;
    border-radius: 15px;
    overflow: hidden;
}

.task-detail::-webkit-scrollbar {
    display: none;
}

.task-selected{
    background-color: #e2e2ee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
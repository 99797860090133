.year-th{
    width: 100%;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    margin-top: 8px;
    border-bottom: 1px solid #000;
}

.year-div{
    width: 100%;
    text-align: center;
}

.year-td{
    width: 100%;
    max-width: 180px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    text-align: center;
    display: inline-block;
}
.day-th{
    width: 100%;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
    margin-top: 8px;
}

.day-th-underline{
    width: 100%;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
    margin-top: 8px;
    border-bottom: 1px solid #ddd;
}

.day-div{
    width: 100%;
    text-align: left;
    min-height: 10px;
    margin-left: 1%;
    padding-bottom: 200px;
}

.day-td{
    min-height: 24px;
    width: 96%;
    font-size: 18px;
    line-height: 24px;
    margin-left: 1%;
}


.day-p{
    margin: 6px 0;
    margin-right: 4px;
    line-height: 24px;
}

.day-table{
    width: 100%;
    height: calc(100% - 51px);
    margin-left: 1%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-collapse: collapse;
    border-spacing: 0;
}

.day-table::-webkit-scrollbar {
    display: none;
}

.day-bell{
    vertical-align: bottom;
    line-height: 24px;
    font-size: 22px;
}
.profile-google-button{
    width: 80%;
    max-width: 340px;
    height: 50px;
    border: none;
    background-color: #4285f4;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 20px auto;
}

.profile-header{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    padding: 0;
    text-align: left;
    border-top: 1px solid #ddd;
    display: flex;
    position: absolute;
    bottom: 51px;
}

.profile-user-icon{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: 10px 10px;
}

.profile-user-text{
    display: inline-block;
    width: calc(100% - 70px);
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    text-align: left;
    margin: 0px 0px;
}

.profile-list{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    padding: 0;
    padding-left: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    display: flex;
}

.profile-div{
    width: 100%;
    height: calc(100% - 51px);
}

.profile-calender{
    width: 100%;
    height: calc(100% - 51px);
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: auto;
}

.profile-chunk{
    min-width: 140px;
    height: 100%;
}

.profile-sticker-div{
    width: 100%;
    height: calc(100% - 49px);
    line-height: calc(100% - 25px);
    font-size: 20px;
    padding: 0;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.profile-sticker{
    width: 100px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    padding: 0;
    text-align: center;
}

.profile-time{
    width: 100%;
    height: 48px;
    line-height: 24px;
    font-size: 20px;
    padding: 0;
    text-align: center;
}

.profile-sticker-list-div{
    width: 100%;
    height: 300px;
    max-height: 25%;
    position: absolute;
    bottom: 51px;
    background-color: white;
    border-top: 1px solid #ddd;
}

.profile-sticker-list{
    width: 100%;
    height: calc(100% - 42px);
    padding: 0 15px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    text-align: left;
}


.profile-select-button{
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 40px;
    font-size: 20px;
    padding: 0;
    margin: 4px 4px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.profile-sticker-div-button{
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 40px;
    font-size: 20px;
    padding: 6px;
    right: 0px;
    top: 0px;
    background-color: white;
}

.profile-category-div{
    width: 100%;
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    padding: 0 6px;
    padding-right: 36px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.profile-category-div::-webkit-scrollbar{
    display: none;
}

.profile-category-button{
    min-width: 24px;
    height: 24px;
    line-height: 40px;
    font-size: 20px;
    padding: 0;
    margin: 9px 10px;
    background-color: white;
}

.profile-category-button-selected{
    min-width: 24px;
    height: 24px;
    line-height: 40px;
    font-size: 20px;
    padding: 0;
    margin: 9px 10px;
    color: #42ddb4;
    background-color: white;
}

.profile-time-button-div{
    position: absolute;
    right: 18px;
    bottom: 170px;
    width: 40px;
    height: 80px;
    line-height: 40px;
    font-size: 20px;
    padding: 0;
    text-align: center;
    border: 1px solid #ddd;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #ddd;
}

.profile-time-button{
    display: block;
    width: 40px;
    height: 40px;
    font-size: 20px;
    box-sizing: border-box;
    padding: 6px;
    margin: 0;
    border: none;
    background-color: white;
}
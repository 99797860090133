

.month-div{
    width: 100%;
    text-align: left;
    min-height: 10px;
    margin-left: 1%;
}

.month-td{
    min-height: 24px;
    width: 96%;
    font-size: 18px;
    line-height: 24px;
    margin-left: 1%;
    text-align: left;
}


.month-p{
    margin: 6px 0;
    margin-right: 4px;
    line-height: 24px;
    text-align: left;
}